<script lang="ts">
  "Registration for our April 10th, 2025 Wild at Heart Retreat in Colorado is open.";
  export let CONTENT_API_V1_URL: string = "";
  export let CONTENT_API_AUTH_TOKEN: string = "";

  import { onMount } from "svelte";
  import { ContentV1 } from "@api/content-v1";
  import { slide } from "svelte/transition";

  import type { AlertResponse } from "@api/content-v1";
  let alerts: AlertResponse[] = [];

  onMount(async () => {
    const content = new ContentV1(CONTENT_API_V1_URL, CONTENT_API_AUTH_TOKEN);
    alerts = await content.getAlerts();
  });
</script>

{#if alerts.length > 0}
  <section
    transition:slide={{ duration: 300, axis: "y" }}
    class="p-3 w-full text-center text-white bg-gradient-to-r from-p500/25 to-p500"
  >
    {alerts[0].content?.replace(/<\/?p>/g, "")}
    <a class="underline" href={alerts[0].link_url}>{alerts[0].link_text}</a>
  </section>
{/if}
