<script lang="ts" context="module">
  export interface MediaItemProps {
    mediaType: string;
    seriesTitle?: string;
    title: string;
    subtitle: string;
    publishDate: string;
    imageUrl: string;
    url: string;
    videoUrl?: string;
    backgroundImageUrl?: string;
    button?: ButtonProps;
  }
</script>

<script lang="ts">
  import { type ButtonProps } from "@elements/Button.svelte";
  import { playEpisode } from "../../stores/playerStore.ts";

  export let props: MediaItemProps;
  let hideImage = false;
  let zoomImage = false;

  $: subtitleExcerpt = props.subtitle?.substring(0, 25) + "...";

  function handleMouseEnter(e: MouseEvent) {
    zoomImage = true;
  }

  function handleMouseLeave(e: MouseEvent) {
    zoomImage = false;
  }

  function handleClick() {
    playEpisode({
      podcastTitle: props.seriesTitle || "",
      podcastId: "",
      attributes: {
        title: props.title,
        published_at: props.publishDate,
        description: "",
        image_url:
          "https://img.transistor.fm/FjQyNIwe7q2T1FDO3O-4QzolGFBHEmaiBQre2EHtGnU/rs:fill:3000:3000:1/q:60/aHR0cHM6Ly9pbWct/dXBsb2FkLXByb2R1/Y3Rpb24udHJhbnNp/c3Rvci5mbS9lcGlz/b2RlLzE1NjIxODUv/MTY5ODI0OTY5Ny1h/cnR3b3JrLmpwZw.webp",
        media_url:
          "https://audio.transistor.fm/m/shows/26178/09dc11598af6e167e2ef47e1230c788e.mp3",
      },
    });
  }
</script>

<a
  href={props.url}
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
  class="min-w-60 w-1/2 bg-white aspect-square flex flex-col justify-between rounded-2xl md:rounded-3xl overflow-hidden relative"
>
  <img
    class={`h-full object-cover absolute w-full z-10 transition-transform duration-700 transform-gpu     ${hideImage ? "opacity-0" : "opacity-100"}  ${zoomImage ? "scale-110" : "scale-100"}`}
    width="250"
    alt={props.title}
    src={props.imageUrl}
  />
  <div
    class="z-20 relative h-full bg-cover bg-center overflow-hidden"
    style="background: linear-gradient(180deg, rgba(41, 53, 58, 0.6) 0%, rgba(15, 28, 34, 0.6) 99.8%);"
  >
    <img
      class="object-cover h-full mix-blend-color-burn blur-md transform scale-300 absolute"
      src={props.backgroundImageUrl}
      alt=""
    />
    <div class="absolute bottom-0 z-30 text-white p-8 w-3/4">
      <h4 class="text-body-small">
        {props.seriesTitle}
      </h4>
      <h3 class="pt-2">
        {props.title}
      </h3>
    </div>
  </div>
</a>
